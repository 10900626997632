import { useNavigate } from "react-router-dom";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";

import { authActions, getAuth, useAuth } from "~/providers/store/auth";
import { useAppDispatch, useDeviceData } from "~/providers/app";
import { logger } from "~/services/logger";

import { useSendWebEvent } from "../useSendWebEvent";

import type { apiContract } from "./api.client";
import { client, getAuthHeader } from "./api.client";

export const tillsKeys = createQueryKeys("tills", {
  list: ({ assigned }: { assigned: boolean }) => ({
    queryKey: [{ assigned }],
  }),
  detail: (tillId: string) => ({ queryKey: [tillId] }),
});

/**
 * Hook to fetch the active (assinged to a device) tills
 * from the same location as the current device
 */
function useTills() {
  const { moduleId, schoolId, tillId } = getAuth();
  return useQuery({
    ...tillsKeys.list({ assigned: true }),
    queryFn: async () => {
      const res = await client.v1.pos.tills.get({
        query: {
          moduleId: moduleId ?? undefined,
          schoolId: schoolId ?? undefined,
          assigned: true,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    select: (tills) => {
      const fromIndex = tills.findIndex((till) => till.id === tillId);

      if (fromIndex > 0) {
        const element = tills[fromIndex];
        tills.splice(fromIndex, 1);
        tills.splice(0, 0, element);
      }

      return tills;
    },
  });
}

/**
 * Hook to fetch the unassigned tills from the same location
 */
function useUnassignedTills() {
  return useQuery({
    ...tillsKeys.list({ assigned: false }),
    queryFn: async () => {
      const { partnerId } = getAuth();

      const res = await client.v1.pos.tills.get({
        query: {
          partnerId: partnerId ?? undefined,
          assigned: false,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
  });
}

function useTill(tillId: string | null | undefined, deviceId?: string | null) {
  return useQuery({
    ...tillsKeys.detail(tillId || "empty"),
    queryFn: async () => {
      if (!tillId) throw new Error("No till id provided");

      const res = await client.v1.pos.tills.getById({
        params: { tillId },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        // only check for useCurrentTill
        if (typeof deviceId === "string") {
          const { isKanplaAdmin } = getAuth();

          // allow kanpla admin to access any till (switch feature)
          if (!isKanplaAdmin && res.body.deviceId !== deviceId) {
            logger.warn(
              "Till is assigned to a different device than the current one",
              { till_id: tillId, device_id: deviceId }
            );

            authActions.logoutTill();
            throw res;
          }
        }

        return res.body;
      }

      throw res;
    },
    enabled: Boolean(tillId),
    refetchInterval: 1000 * 60 * 5,
  });
}

function useCurrentTill() {
  const { tillId } = useAuth();
  const { deviceId } = useDeviceData();

  return useTill(tillId, deviceId);
}

type UpdateRequest = ClientInferRequest<typeof apiContract.v1.pos.tills.update>;

function useAssignTill() {
  const dispatch = useAppDispatch();
  const sendWebEvent = useSendWebEvent();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({
      tillId,
      deviceId,
    }: Pick<UpdateRequest["body"], "deviceId"> & {
      tillId: string;
    }) => {
      const res = await client.v1.pos.tills.update({
        params: { tillId },
        body: { deviceId },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    onSuccess: (till) => {
      void queryClient.invalidateQueries({
        queryKey: tillsKeys.detail(till.id).queryKey,
      });

      dispatch({
        type: "DEVICE_ASSIGN_TILL",
        payload: till.id,
      });

      sendWebEvent({
        type: "DEVICE_DATA_SET_REQUEST",
        payload: { tillId: till.id },
      });

      authActions.assignTill({
        moduleId: till.moduleId,
        userId: till.moduleId,
        schoolId: till.schoolId,
        tillId: till.id,
        language: till.language,
      });

      navigate("/profiles");
    },
  });
}

function useUpdateTill() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateRequest["body"]) => {
      const { tillId, status } = getAuth();
      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v1.pos.tills.update({
        body,
        params: { tillId },
        headers: getAuthHeader(),
      });
      if (res.status === 200) {
        return res.body;
      }
      throw res;
    },
    onSuccess: async (till) => {
      await queryClient.invalidateQueries({
        queryKey: tillsKeys.detail(till.id).queryKey,
      });

      if (till.language) {
        authActions.updateLanguage(till.language);
      }
    },
  });
}

export {
  useAssignTill,
  useCurrentTill,
  useTill,
  useTills,
  useUnassignedTills,
  useUpdateTill,
};
