import { useSpring, animated } from "@react-spring/web";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "~/components";
import { T } from "@repo/transifex";

export const TicketValidScreen = ({ onClose }: { onClose: () => void }) => {
  const props = useSpring({
    from: { width: "0%" },
    to: { width: "100%" },
    config: { duration: 3000, friction: 120, tension: 120 },
    onRest: onClose,
  });

  return (
    <div className="flex h-full flex-col items-center justify-center bg-primary-dark">
      <FontAwesomeIcon
        icon={faCheckCircle}
        fontSize={250}
        className="text-white"
      />
      <div className="flex flex-col items-center justify-center gap-4 pb-12 pt-6">
        <p className="text-7xl font-semibold text-white">
          <T _str="Ticket confirmed" />
        </p>
        <p className="text-2xl text-white">
          <T _str="Head over to collect your order. Enjoy your meal!" />
        </p>
      </div>
      <Button
        className="relative overflow-hidden !rounded-xl !bg-white/10 !px-32"
        variant="primary"
        size="large"
        onClick={onClose}
      >
        <animated.div
          className="absolute inset-y-0 left-0 h-full bg-white/30"
          style={{ ...props }}
        />
        <div className="relative">
          <T _str="Close" />
        </div>
      </Button>
    </div>
  );
};
