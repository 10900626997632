import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";

import type { Plugins } from "@repo/types";
import { T, useT } from "@repo/transifex";
import {
  priceFormatter,
  validateRequiredOrder,
  isApiError,
} from "@repo/system";
import {
  BaseInput,
  Button,
  Dialog,
  DialogError,
  DialogLoading,
  DialogPayment,
} from "~/components";
import {
  ordersKeys,
  useCurrentTill,
  useOrderCreate,
  useSchool,
} from "~/hooks/queries";
import { useBasketProducts } from "~/providers/app";
import { useAuth } from "~/providers/store/auth";
import {
  useBasketWithCategories,
  useDiningOption,
  useShopperName,
} from "~/providers/store/basket";
import { useEmployee } from "~/providers/store/employee";
import { calculateAmountForSaldo } from "~/utils/basket";

import { StepSelectors } from "./steps/StepSelectors";
import { StepTextInputs } from "./steps/StepTextInputs";

type InvoiceFunnelStepName = "selectors" | "text-inputs";

type Step = { name: InvoiceFunnelStepName; title: string };

const steps: Step[] = [
  { name: "selectors", title: "Pay by Invoice" },
  { name: "text-inputs", title: "Add note to invoice" },
];

export type InvoiceStepSelectorsType = {
  selector: string | null;
};

export type InvoiceStepTextInputsType = {
  textInputs: Record<
    Plugins.TextInputField["key"],
    | string
    | { value: string; label: string }
    | { value: string; label: string }[]
  > | null;
};

type InvoiceFunnelType = InvoiceStepSelectorsType & InvoiceStepTextInputsType;

export const InvoicePage = () => {
  const t = useT();
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState("");

  const { locale, schoolId } = useAuth();
  const { totalUnitDiscounted, meta, productsInBasket } = useBasketProducts();
  const basketWithCategories = useBasketWithCategories();
  const { employeeUserId, employeeChildId, saldo: unitSaldo } = useEmployee();
  const diningOption = useDiningOption();
  const shopperName = useShopperName();

  const { data: till } = useCurrentTill();

  const {
    mutate: createOrder,
    status,
    error: createOrderError,
    data: order,
  } = useOrderCreate();

  const { data: school } = useSchool(schoolId, query);

  const { payWithOther, payWithSaldo } = calculateAmountForSaldo(
    totalUnitDiscounted,
    unitSaldo
  );

  const totalPriceFormatted = priceFormatter({
    value: payWithOther,
    currency: meta.currency,
    locale,
  });

  // STEP TWO DATA
  const textInputFields = useMemo(() => {
    const basketLines = productsInBasket.map((product) => ({
      amount: product.quantity,
      productId: product.id,
    }));

    return (till?.postCheckoutTextInputs ?? []).filter((textInputField) => {
      return validateRequiredOrder(basketLines, textInputField.requiredOrder);
    });
  }, [productsInBasket, till?.postCheckoutTextInputs]);

  // FUNNEL INFO
  const [currentStep, setCurrentStep] =
    useState<InvoiceFunnelStepName>("selectors");

  const methods = useForm<InvoiceFunnelType>();
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = (data: InvoiceFunnelType) => {
    if (currentStep === "selectors" && textInputFields.length) {
      setCurrentStep("text-inputs");
      return;
    }

    const textInputs = formatTextInputs(data.textInputs);

    const diningOptionInfo = diningOption
      ? {
          [diningOption.inputId]: diningOption.value,
        }
      : {};

    createOrder(
      {
        paymentMethod: "billing-selector",
        basket: basketWithCategories,
        groupName: data.selector || "",
        employeeUserId,
        employeeChildId,
        paidWithSaldoUnit: payWithSaldo,
        customShopperName: shopperName || undefined,
        ...(textInputs && {
          info: {
            textInput: {
              ...textInputs,
              ...diningOptionInfo,
            },
          },
        }),
      },
      {
        onSuccess: async ({ id: orderId }) => {
          setSearchParams({ orderId });
          await queryClient.invalidateQueries({
            queryKey: ordersKeys.detail(orderId).queryKey,
          });
        },
      }
    );
  };

  if (status === "pending") {
    return <DialogLoading title={<T _str="Creating order..." />} />;
  }

  if (order && (status === "success" || status === "error")) {
    return (
      <DialogPayment
        orderId={order.id}
        status={order?.status === "paid" ? "success" : "error"}
      />
    );
  }

  if (status === "error" && createOrderError) {
    return (
      <DialogError
        error={
          isApiError(createOrderError)
            ? createOrderError.body.message
            : "Could not create order"
        }
      />
    );
  }

  return (
    <Dialog className="w-4/5" size="lg">
      <FormProvider {...methods}>
        <form
          className="relative flex w-full flex-col items-center gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex w-full flex-col items-center gap-8 px-10 py-5">
            <div className="flex w-full items-center justify-between">
              <Link className="w-60" to="..">
                <Button
                  className="font-medium text-danger-dark"
                  variant="transparent"
                >
                  <T _str="Cancel" />
                </Button>
              </Link>

              <h1 className="text-2xl font-bold">
                <T
                  _str={steps.find((step) => step.name === currentStep)?.title}
                />
              </h1>

              <div className="w-60">
                {currentStep === "selectors" ? (
                  <BaseInput
                    className="w-full"
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                    placeholder={t("Search")}
                    suffix={<FontAwesomeIcon icon={faSearch} />}
                    value={query}
                  />
                ) : null}
              </div>
            </div>

            <div className="flex max-h-64 w-full justify-center overflow-scroll">
              {currentStep === "selectors" ? (
                <StepSelectors selectors={school?.selector?.options ?? []} />
              ) : null}
              {currentStep === "text-inputs" ? (
                <StepTextInputs textInputFields={textInputFields} />
              ) : null}
            </div>
          </div>

          <div className="flex w-full flex-col gap-4 bg-background-secondary px-10 py-4">
            <div className="flex items-center justify-between">
              <p className="text-lg text-text-secondary">
                <T _str="Total" />
              </p>

              <p className="text-4xl font-semibold text-primary-dark">
                {totalPriceFormatted}
              </p>
            </div>

            <Button
              disabled={!isValid}
              size="large"
              type="submit"
              variant="primary"
            >
              <T
                _str={
                  currentStep === "text-inputs" || !textInputFields.length
                    ? "Confirm"
                    : "Next"
                }
              />
            </Button>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

type TextInputsFormatted = Record<string, string[] | string>;

function formatTextInputs(
  raw: InvoiceStepTextInputsType["textInputs"]
): TextInputsFormatted {
  if (raw === null) {
    return {};
  }

  const formatted: TextInputsFormatted = {};

  for (const key in raw) {
    const value = raw[key];

    if (value !== undefined) {
      if (typeof value === "string" && value.trim() !== "") {
        // Text input field
        formatted[key] = value;
      } else if (Array.isArray(value)) {
        // Select input field with multiple values
        const filteredArray = value.map((item) => item.value);
        if (filteredArray.length > 0) {
          formatted[key] = filteredArray;
        }
      } else if (value && typeof value === "object" && "value" in value) {
        // Select input field with single value
        formatted[key] = value.value;
      }
    }
  }

  return formatted;
}
