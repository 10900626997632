import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { clsx } from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

import type { AiColors, PosProduct } from "@repo/types";
import { T, useT } from "@repo/transifex";
import { ItemLayout } from "~/components/ItemLayout";
import { useAppContext, useAppDispatch } from "~/providers/app";
import { basketActions } from "~/providers/store/basket";
import { modalVariantsActions } from "~/providers/store/modal-variants";
import { useSendWebEvent } from "~/hooks";
import { useCurrentTill } from "~/hooks/queries";
import { PWA_ENV } from "~/utils/constants";

function getBadgeByLimit(
  limit: number | null,
  t: ReturnType<typeof useT>
): {
  disabled: boolean;
  badge: string | undefined;
} {
  if (typeof limit !== "number") return { disabled: false, badge: undefined };

  if (limit > 0) {
    const left = limit;
    return {
      disabled: false,
      badge: t("{left} Left", { left: left > 9 ? "9+" : left }),
    };
  }

  return { disabled: true, badge: t("Limit reached") };
}

function getBadgeByDates(
  deadlineDate: number | null,
  onsetDate: number | null,
  offset: number,
  t: ReturnType<typeof useT>
): {
  disabled: boolean;
  badge: string | undefined;
} {
  if (!deadlineDate && !onsetDate) return { disabled: false, badge: undefined };

  let disabled = false;
  let badge;

  if (onsetDate) {
    const isToday = moment.utc(onsetDate * 1000).isSame(moment().utc(), "day");
    const isOnSetPassed =
      isToday && onsetDate < moment().add(offset, "minutes").unix();

    if (isToday && !isOnSetPassed) {
      disabled = true;
      badge = t("Available after {date}", {
        date: moment.unix(onsetDate).add(-offset, "minutes").format("HH:mm"),
      });
    }
  }

  if (deadlineDate && !badge) {
    disabled = false;
    badge = t("Buy before {date}", {
      date: moment.unix(deadlineDate).add(-offset, "minutes").format("HH:mm"),
    });
  }

  return { disabled, badge };
}

/**
 * Checks if a product is available based on its deadline and an offset.
 *
 * @param {PosProduct} product - The product to check availability for.
 * @param {number} offset - The offset in minutes to consider for the deadline.
 * @returns {boolean} - Returns true if the product is available, otherwise false.
 */
const isPossibleToOrderToday = (
  deadline: number | null,
  offset: number
): boolean => {
  if (!deadline) {
    return true;
  }

  const deadlineMoment = moment.utc(deadline * 1000);
  const now = moment().utc();
  const isToday = deadlineMoment.isSame(now, "day");
  const isDeadlinePassed =
    isToday && deadline < now.add(offset, "minutes").unix();

  return isToday && !isDeadlinePassed;
};

export const ProductItem = ({
  product,
  offset,
  disabled,
  unitSystem,
}: {
  product: PosProduct;
  offset: number;
  disabled: boolean;
  unitSystem?: string;
}) => {
  const t = useT();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const sendWebEvent = useSendWebEvent();

  const {
    hardware: {
      scales: { scale },
    },
  } = useAppContext();

  const { data: till, status } = useCurrentTill();

  const {
    name,
    displayPrice,
    displayPriceOriginal,
    limit,
    deadline,
    onset,
    discount,
  } = product;

  const [datesInfo, setDatesInfo] = useState(() =>
    getBadgeByDates(deadline, onset, offset, t)
  );
  const { disabled: disabledByTime, badge: badgeTime } = datesInfo;

  const { disabled: disabledByLimit, badge: badgeLimit } = getBadgeByLimit(
    limit,
    t
  );

  const shouldDisplayDiscount =
    Boolean(product.discount?.type === "discount-hybridBilling") &&
    Boolean(product.discount?.allowanceType !== "unitPrice");

  useEffect(() => {
    if (!deadline && !onset) return;
    const intervalId = setInterval(() => {
      setDatesInfo(getBadgeByDates(deadline, onset, offset, t));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [deadline, onset, offset, t]);

  const hasVariants = product.options.length > 0;
  const isWeightedProduct = product.unitSystem !== "piece";

  const unitSystemLabel = unitSystem
    ? {
        "1-gram": t("pr. 1 g"),
        "100-grams": t("pr. 100 g"),
        kilogram: t("pr. 1 kg"),
      }[unitSystem]
    : "";

  if (status !== "success") return null;

  const hasColor = till.config.hasProductColors;

  // if the deadline to order the product is passed
  if (!isPossibleToOrderToday(deadline, offset)) {
    return null;
  }

  return (
    <ItemLayout
      badge={badgeTime ?? badgeLimit}
      className={clsx(
        "flex size-full flex-1 flex-col justify-end gap-2 bg-ai-gray-primary p-4 text-left text-lg text-ai-gray-secondary",
        hasColor &&
          typeof product.color !== "undefined" &&
          getColorClassNames(product.color)
      )}
      disabled={disabledByTime || disabledByLimit || disabled}
      onClick={() => {
        dispatch({ type: "COUNTDOWN_RESET" });

        switch (true) {
          case isWeightedProduct: {
            if (scale) {
              const scaleData = "model" in scale ? scale.scale : scale;
              sendWebEvent({
                type: "SCALE_CONNECT_REQUEST",
                payload: scaleData,
              });
            }
            navigate(`${pathname}/weight-product/${product.id}`);
            break;
          }
          case hasVariants:
            modalVariantsActions.init({
              productId: product.id,
              options: product.options,
            });
            navigate(`/product/${product.id}/variants`);
            break;
          default:
            basketActions.addRegular(product.id);
        }
      }}
    >
      <span className="line-clamp-2 text-2xl leading-tight">{name}</span>
      {/* Product is eligible for hybrid billing discount */}
      {discount ? (
        <div className="absolute right-2 top-2">
          <FontAwesomeIcon
            icon={faStar}
            className={clsx({
              // Always display green star for hybrid billing in production
              "!text-green-500":
                discount.type === "hybridBilling" || PWA_ENV === "production",
              "text-blue-500": discount.type === "discount-hybridBilling",
              "text-orange-500": discount.allowanceType === "unitPrice",
              "text-red-500": discount.allowanceType === "numberOfProducts",
            })}
          />
        </div>
      ) : null}
      {hasVariants ? (
        <span className="text-base opacity-70">
          <T _str="Choose variant" />
        </span>
      ) : (
        <div className="text-base">
          {till.moduleConfig.hidePrices ? null : (
            <span>
              {shouldDisplayDiscount ? (
                <span className="mr-2 line-through opacity-70">
                  {displayPriceOriginal}
                </span>
              ) : null}
              <span>{displayPrice}</span>
            </span>
          )}

          {isWeightedProduct ? (
            <span className="ml-2 opacity-70">{unitSystemLabel ?? ""}</span>
          ) : null}
        </div>
      )}
    </ItemLayout>
  );
};

const getColorClassNames = (color: AiColors) => {
  const colorMap: Record<AiColors, string> = {
    lime: "!bg-ai-lime-primary !text-ai-lime-secondary",
    mint: "!bg-ai-mint-primary !text-ai-mint-secondary",
    teal: "!bg-ai-teal-primary !text-ai-teal-secondary",
    blue: "!bg-ai-blue-primary !text-ai-blue-secondary",
    navy: "!bg-ai-navy-primary !text-ai-navy-secondary",
    purple: "!bg-ai-purple-primary !text-ai-purple-secondary",
    pink: "!bg-ai-pink-primary !text-ai-pink-secondary",
    red: "!bg-ai-red-primary !text-ai-red-secondary",
    brown: "!bg-ai-brown-primary !text-ai-brown-secondary",
    orange: "!bg-ai-orange-primary !text-ai-orange-secondary",
    yellow: "!bg-ai-yellow-primary !text-ai-yellow-secondary",
    gray: "!bg-ai-gray-primary !text-ai-gray-secondary",
  };

  return colorMap[color] || "";
};
