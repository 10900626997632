import { useMutation } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth";

import { client, getAuthHeader } from "./api.client";

export const useVerifyTicket = () => {
  return useMutation({
    mutationFn: async (qrCode: string) => {
      const { tillId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const result = await client.v1.pos.tills.validateQr({
        headers: getAuthHeader(),
        params: {
          tillId,
        },
        body: {
          code: qrCode,
        },
      });

      if (result.status === 200) {
        return result.body;
      }

      throw result;
    },
  });
};
