import { faWifiSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";
import { InitialsCircle } from "~/components";
import { authActions, useAuth, useIsOnline } from "~/providers/store/auth";
import { basketActions } from "~/providers/store/basket";
import { useProfile } from "~/hooks/queries";

import { UpdatesInfo } from "./UpdatesInfo";
import { UpdatePWA } from "./UpdatePWA";

export const MenuHeader = () => {
  const auth = useAuth();
  const { data: profile } = useProfile(auth.profileId);
  const isOnline = useIsOnline();

  const handleLogout = () => {
    basketActions.reset();
    basketActions.resetDiningOption();
    authActions.logoutProfile();
  };

  return (
    <div className="grid grid-cols-3 px-5 pt-10">
      <div />
      <div className="flex items-center justify-center">
        {!isOnline && (
          <div className="flex flex-col items-center gap-1.5">
            <div className="flex w-max items-center gap-2.5 rounded-lg bg-warning-backdrop/20 px-3 py-2 text-warning-main">
              <FontAwesomeIcon icon={faWifiSlash} />
              <p>
                <T _str="Device is offline. Please check the Wi-fi connection" />
              </p>
            </div>
            <UpdatesInfo />
          </div>
        )}

        <UpdatePWA />
      </div>
      <div className="flex items-center justify-end">
        {isOnline && profile ? (
          <button
            className="flex h-16 items-center gap-3 rounded-md bg-secondary-backdrop/20 pl-2.5 pr-4 text-secondary-main"
            onClick={handleLogout}
            type="button"
          >
            <InitialsCircle name={profile.name} />
            <T _str="Log out" />
          </button>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
