import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { T } from "@repo/transifex";
import { Switch } from "~/components/Switch";
import { useSuppliersGenerateColors } from "~/hooks/queries";
import { DialogError } from "~/components";
import { isApiError } from "@repo/system";

import { DeviceWrapper } from "./Device";

type ProductColorsProps = {
  hasProductColors: boolean;
  updateTill: ({ hasProductColors }: { hasProductColors: boolean }) => void;
  updateTillStatus: "error" | "success" | "pending" | "idle";
};

export const ProductColors = ({
  hasProductColors,
  updateTill,
  updateTillStatus,
}: ProductColorsProps) => {
  const {
    mutate: generateColor,
    status: generateColorStatus,
    reset,
    error,
  } = useSuppliersGenerateColors();

  const apiError = isApiError(error)
    ? error.body.message
    : "Could not generate the colors";

  return (
    <>
      <div>
        <p className="mb-1 text-2xl font-bold">
          <T _str="Theme" />
        </p>

        <p className="mb-2.5 text-gray-600">
          <T _str="Our AI analyzes your products and suggests the most suitable colors, making selection quick and intuitive" />
        </p>

        <DeviceWrapper>
          {(generateColorStatus === "pending" ||
            updateTillStatus === "pending") && (
            <div className="flex w-full items-center justify-center gap-x-4">
              <FontAwesomeIcon
                className="text-5xl text-text-secondary"
                icon={faSpinner}
                spinPulse
              />
              <span className="text-text-secondary">
                <T _str="Generating colors may take a moment, please don't close settings" />
              </span>
            </div>
          )}
          {generateColorStatus !== "pending" &&
            updateTillStatus !== "pending" && (
              <>
                <div className="flex flex-col">
                  <p>
                    <T _str="Use product colors" />
                  </p>
                </div>
                <Switch
                  checked={hasProductColors}
                  handleChange={(checked) => {
                    generateColor(
                      { hasProductColors: checked },
                      {
                        onSuccess: () => {
                          updateTill({
                            hasProductColors: checked,
                          });
                        },
                      }
                    );
                  }}
                />
              </>
            )}
        </DeviceWrapper>
      </div>

      {generateColorStatus === "error" ? (
        <DialogError childrenPosition="top" onOutsideClick={reset}>
          <T _str={apiError} />
        </DialogError>
      ) : null}
    </>
  );
};
