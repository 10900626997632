import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "~/components";
import { T } from "@repo/transifex";

export const TicketErrorScreen = ({
  onClose,
  title,
  body,
  icon,
}: {
  onClose: () => void;
  title: string;
  body: string;
  icon: IconDefinition;
}) => {
  return (
    <div className="flex h-full flex-col items-center justify-center bg-danger-main">
      <FontAwesomeIcon icon={icon} fontSize={250} className="text-white" />
      <div className="flex flex-col items-center justify-center gap-4 pb-12 pt-6">
        <p className="text-7xl font-semibold text-white">{title}</p>
        <p className="text-2xl text-secondary-light">{body}</p>
      </div>
      <Button
        className="!rounded-xl bg-white/10 !px-32 shadow-md"
        variant="danger"
        size="large"
        onClick={onClose}
      >
        <T _str="Close" />
      </Button>
    </div>
  );
};
