import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { priceFormatter, isApiError } from "@repo/system";
import { T, useT } from "@repo/transifex";
import {
  Button,
  Dialog,
  DialogConfirm,
  DialogError,
  DialogLoading,
  DialogSuccess,
  type DialogProps,
} from "~/components";
import {
  useAbortTransaction,
  useCurrentTill,
  useOrder,
  useOrderRefund,
  useTransactionStatus,
} from "~/hooks/queries";
import { useAuth } from "~/providers/store/auth";
import { refundActions, useRefundInfo } from "~/providers/store/refund";
import { TerminalReadyIcon } from "~/routes/__app.pos.$categoryId.card/TerminalReadyIcon";
import { formatRefundOrderLines } from "~/utils/refund-line-formatter.ts";

import { DialogChooseOrderLines } from "./DialogChooseOrderLines";
import { DialogChooseRefundMethod } from "./DialogChooseRefundMethod";

type DialogRefundProps = Partial<DialogProps> & {
  orderId: string;
};

export const DialogRefund = ({
  orderId,
  ...dialogProps
}: DialogRefundProps) => {
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");

  const { locale } = useAuth();
  const { data: till } = useCurrentTill();
  const terminalId = till?.terminalId ?? undefined;

  const {
    data: order,
    status: orderStatus,
    error: orderError,
  } = useOrder(orderId);

  const {
    mutate: refundOrder,
    status: refundStatus,
    reset,
    error: refundError,
  } = useOrderRefund();

  const t = useT();

  const { mutate: abortTransaction, status: abortTransactionStatus } =
    useAbortTransaction();
  useTransactionStatus({ serviceId, orderId, terminalId });

  const refundInfo = useRefundInfo();

  const [openChooseMethodDialog, setOpenChooseMethodDialog] = useState(false);

  if (orderStatus === "pending") return <DialogLoading />;

  if (refundError || orderError) {
    const error = refundError ?? orderError;

    return (
      <DialogError
        error={
          isApiError(error) ? error.body.message : t("Could not refund order")
        }
        onOutsideClick={() => {
          reset();
        }}
      />
    );
  }

  const isSplitPayment = order.payments && order.payments.length > 1;
  const unreferencedRefund =
    order.payments?.some(
      (p) => p.paymentProvider !== "card" && p.paymentProvider !== "swish"
    ) || isSplitPayment;
  const isBilling = order.payments?.some(
    (p) => p.paymentProvider === "billing"
  );

  // The order was paid in full with Swish
  const isFullSwish = Boolean(
    order.payments?.some((payment) => payment.paymentProvider === "swish") &&
      order.payments?.length === 1
  );

  const refundedOrderPrice = refundInfo.selectedOrderLines.reduce(
    (acc, { amount, unitPrice }) => acc + amount * unitPrice,
    0
  );

  const formattedRefundLines = formatRefundOrderLines(
    order,
    order.currency,
    locale
  );

  if (refundStatus === "success") {
    return (
      <DialogSuccess
        onOutsideClick={() => dialogProps.onOutsideClick?.()}
        title="Refund complete!"
      >
        {refundInfo.refundMethod === "cash" ? (
          <div className="mt-5 flex flex-col items-center">
            <p>
              <T _str="Cash to refund:" />
            </p>
            <p>
              {priceFormatter({
                value: refundedOrderPrice,
                currency: order.currency,
                locale,
              })}
            </p>
          </div>
        ) : null}
        {refundInfo.refundMethod === "credit" ? (
          <p className="text-center">
            <T _str="The amount has been refunded to the users balance. They will be able to see the amount on their app under profile." />
          </p>
        ) : null}
        {!unreferencedRefund && refundInfo.refundMethod === "card" ? (
          <p className="text-center">
            <T _str="Money will be automatically transferred to card within 2-3 business days" />
          </p>
        ) : null}
      </DialogSuccess>
    );
  }

  if (refundStatus === "pending") {
    if (refundInfo.refundMethod === "card" && !isBilling && isSplitPayment) {
      return (
        <Dialog className="max-w-sm" size="md">
          <div className="flex flex-col items-center gap-y-5 p-5">
            <p className="text-base">
              <T _str="Amount" />
            </p>

            <p className="text-2xl font-bold">
              {priceFormatter({
                value: -refundedOrderPrice,
                currency: order.currency,
                locale,
              })}
            </p>

            <div className="flex w-full flex-col items-center gap-5 rounded-2xl bg-background-secondary p-5">
              <p className="text-lg font-bold text-primary-light">
                <T _str="Terminal ready" />
              </p>

              <TerminalReadyIcon />
            </div>

            <Button
              disabled={!orderId || !terminalId}
              loading={abortTransactionStatus === "pending"}
              onClick={() => {
                if (!terminalId) {
                  throw new Error(
                    "Cannot abort transaction: missing terminalId"
                  );
                }
                abortTransaction({ orderId, serviceId });
                dialogProps.onOutsideClick?.();
              }}
              variant="danger"
            >
              <T _str="Cancel" />
            </Button>
          </div>
        </Dialog>
      );
    }

    return <DialogLoading />;
  }

  if (order?.totalPriceUnit === 0) {
    return (
      <DialogConfirm
        onConfirm={() => {
          refundOrder({
            orderId,
            refundMethod: "cash",
            shouldGenerateServiceId: unreferencedRefund,
            refundOrderLines: refundInfo.selectedOrderLines,
          });
        }}
        onOutsideClick={() => {
          setOpenChooseMethodDialog(false);
          dialogProps.onOutsideClick?.();
        }}
        size="md"
        title={t("Confirm refund")}
      />
    );
  }

  if (openChooseMethodDialog) {
    if (order.payments?.some((p) => p.paymentProvider === "billing")) {
      return (
        <DialogConfirm
          onConfirm={() => {
            refundOrder({
              orderId,
              refundMethod: "billing",
              shouldGenerateServiceId: unreferencedRefund,
              refundOrderLines: refundInfo.selectedOrderLines,
            });
          }}
          onOutsideClick={() => {
            setOpenChooseMethodDialog(false);
            dialogProps.onOutsideClick?.();
          }}
          size="md"
          title={t("Confirm refund with invoice")}
        />
      );
    }

    if (order?.paidWithSaldoUnit && order?.paidWithSaldoUnit > 0) {
      return (
        <DialogConfirm
          onConfirm={() => {
            refundActions.setRefundMethod("credit");
            refundOrder({
              orderId,
              refundMethod: "credit",
              shouldGenerateServiceId: unreferencedRefund,
              refundOrderLines: refundInfo.selectedOrderLines,
            });
          }}
          onOutsideClick={() => {
            setOpenChooseMethodDialog(false);
            dialogProps.onOutsideClick?.();
          }}
          size="md"
          title={t("Confirm refund")}
        />
      );
    }

    return (
      <DialogChooseRefundMethod
        isFullSwish={isFullSwish}
        onConfirm={() => {
          refundOrder({
            orderId,
            refundMethod: refundInfo.refundMethod,
            shouldGenerateServiceId: unreferencedRefund,
            refundOrderLines: refundInfo.selectedOrderLines,
          });
        }}
        onOutsideClick={() => {
          setOpenChooseMethodDialog(false);
          dialogProps.onOutsideClick?.();
        }}
      />
    );
  }

  return (
    <DialogChooseOrderLines
      formattedOrderLines={formattedRefundLines}
      onConfirm={(orderLines) => {
        if (orderLines) {
          refundActions.setOrderLines({
            order,
            selectedOrderLines: orderLines.map((o) => o.id),
            formattedOrderLines: orderLines,
          });
        }
        setOpenChooseMethodDialog(true);
      }}
      order={order}
      {...dialogProps}
    />
  );
};
