import {
  faArrowRightArrowLeft,
  faCashRegister,
  faCircleInfo,
  faFileLines,
  faPrint,
  faStore,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T, useT } from "@repo/transifex";
import { useSendWebEvent } from "~/hooks";
import { useAppContext } from "~/providers/app";
import { useAuth, useIsOnline } from "~/providers/store/auth";
import { useCurrentTill, useSession } from "~/hooks/queries";

import {
  Menu,
  MenuColumn,
  MenuContent,
  MenuFooter,
  MenuHeader,
  MenuLink,
  MenuButton,
} from "./components";

export const DefaultPosMenu = () => {
  const auth = useAuth();
  const { data: till } = useCurrentTill();
  const { data: session } = useSession(till?.sessionId);
  const isOnline = useIsOnline();
  const {
    hardware: { printer },
  } = useAppContext();

  const t = useT();
  const sendWebEvent = useSendWebEvent();

  const hasCashDrawer = Boolean(printer?.hasCashDrawer);
  const isTillOpen = Boolean(till?.sessionId);

  const isSessionValid = Boolean(session?.isValid);

  const linkToSalesOrCloseSession = isSessionValid
    ? "/pos"
    : `/settings/tills/${auth.tillId}/${till?.sessionId}/close`;

  const salesMenuLink = isTillOpen
    ? linkToSalesOrCloseSession
    : `/settings/tills/${auth.tillId}/open`;

  return (
    <Menu>
      <MenuHeader />
      <MenuContent>
        {/* Left Column */}
        <MenuColumn>
          <MenuLink
            className="flex-[12_1]"
            icon={faStore}
            isBig
            showOpacity={!isTillOpen || !isSessionValid}
            title={t("Sales")}
            to={salesMenuLink}
          >
            {(!isTillOpen || !isSessionValid) && (
              <div
                className="pointer-events-none absolute inset-x-0 mx-auto flex w-fit items-center gap-2 rounded-md bg-warning-main/20 px-4 py-2 text-warning-main"
                style={{ bottom: "25%" }}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
                {!isTillOpen && <T _str="Open the till to continue" />}
                {!isSessionValid && isTillOpen ? (
                  <T _str="Close till before continuing" />
                ) : null}
              </div>
            )}
          </MenuLink>
          {hasCashDrawer ? (
            <MenuButton
              className="!flex-row"
              disabled={!isTillOpen || !isSessionValid}
              icon={faCashRegister}
              onClick={() => {
                sendWebEvent({ type: "CASH_DRAWER_OPEN_REQUEST" });
              }}
              title={t("Open cash drawer")}
            />
          ) : null}
        </MenuColumn>
        {/* Right Column */}
        <MenuColumn>
          <MenuLink
            to="/settings/tills"
            title={t("Register Management")}
            icon={faCashRegister}
            showWarning={!isSessionValid}
            disabled={!isOnline}
          />
          <MenuLink
            to="/settings/transactions"
            title={t("Transactions")}
            icon={faArrowRightArrowLeft}
          />
          <MenuLink
            to="/settings/reports"
            title={t("Reports")}
            icon={faFileLines}
            disabled={!isOnline}
          />
          <MenuLink
            to="/settings/devices"
            title={t("Devices setup")}
            icon={faPrint}
          />
        </MenuColumn>
      </MenuContent>
      <MenuFooter />
    </Menu>
  );
};
