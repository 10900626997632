import { Link } from "react-router-dom";
import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

import { T } from "@repo/transifex";

type MenuLinkProps = Pick<
  MenuButtonProps,
  | "title"
  | "icon"
  | "isBig"
  | "className"
  | "children"
  | "disabled"
  | "showWarning"
  | "showOpacity"
> & {
  to: string;
};

export const MenuLink = ({
  to,
  children,
  className,
  ...rest
}: MenuLinkProps) => {
  return (
    <Link
      className={clsx(
        "relative flex flex-1",
        {
          "pointer-events-none": rest.disabled,
        },
        className
      )}
      to={to}
    >
      <MenuButton {...rest} />
      {children}
    </Link>
  );
};

type MenuButtonProps = {
  icon: IconDefinition;
  title: string;
  className?: string;
  isBig?: boolean;
  children?: React.ReactNode;
  showWarning?: boolean;
  showOpacity?: boolean;
} & JSX.IntrinsicElements["button"];

export const MenuButton = ({
  title,
  icon,
  className,
  isBig,
  disabled,
  showWarning,
  showOpacity,
  ...rest
}: MenuButtonProps) => {
  return (
    <button
      className={clsx(
        "relative flex size-full flex-1 flex-col items-center justify-center gap-5 rounded-md bg-secondary-backdrop/20 p-5 text-center",
        className,
        { "opacity-30": showOpacity }
      )}
      disabled={disabled}
      type="button"
      {...rest}
    >
      {showWarning ? (
        <div className="absolute -left-2.5 -top-2.5 z-10 aspect-square h-8 rounded-md bg-warning-main p-1 text-white">
          <FontAwesomeIcon icon={faWarning} />
        </div>
      ) : null}

      {disabled ? (
        <>
          <div className="absolute size-full bg-text-primary opacity-80" />
          <div className="absolute left-1/2 top-1/2 aspect-square h-8 -translate-x-1/2 -translate-y-1/2 rounded-md bg-warning-main p-1 text-white">
            <FontAwesomeIcon icon={faWarning} />
          </div>
        </>
      ) : null}

      <FontAwesomeIcon
        className="text-white"
        icon={icon}
        size={isBig ? "2xl" : "lg"}
      />

      <h2 className={clsx(isBig ? "text-5xl" : "text-2xl", "text-white")}>
        <T _str={title} />
      </h2>
    </button>
  );
};
