import type {
  HybridBillingDiscount,
  ProductDiscount,
} from "@kanpla/api-contract";

import { round } from "@repo/system";
import { type PosProduct } from "@repo/types";

type BasicProduct = {
  discount: ProductDiscount | null;
  unitPrice: PosProduct["unitPrice"];
};

export const getEligibleHybridBillingProductDiscount = (
  product: BasicProduct,
  discounts: HybridBillingDiscount[]
): PosProduct["discount"] => {
  const productDiscount = product.discount;
  if (!productDiscount?.id) return null;

  // These discounts are "hybridBilling" and should not be displayed
  if (productDiscount.type === "hybridBilling") {
    return {
      id: productDiscount.id,
      name: "",
      percentage: 0,
      amount: 0,
      type: "hybridBilling",
      allowanceType: null,
    };
  }

  // These discounts are "discount-hybridBilling" and should be displayed
  const eligibleHybridBillingDiscount = discounts.find(
    (discount) =>
      discount.id === productDiscount.id &&
      // only discount-hybridBilling (show full price setting) should be displayed
      discount.type === "discount-hybridBilling" &&
      // flat discount are always "hybridBilling" and should not be displayed
      discount.amountType === "percentage" &&
      // only if the allowance type is numberOfProducts or not set up, the original price should be displayed
      productDiscount.allowanceType !== "unitPrice"
  );

  if (!eligibleHybridBillingDiscount) return null;

  return {
    id: eligibleHybridBillingDiscount.id,
    name: eligibleHybridBillingDiscount.name,
    percentage: eligibleHybridBillingDiscount.amount,
    amount: round(
      (product.unitPrice * eligibleHybridBillingDiscount.amount) / 100,
      0
    ),
    type: "discount-hybridBilling",
    allowanceType: null,
  };
};
