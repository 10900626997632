import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import {
  faQrcode,
  faScanner,
  faScannerTouchscreen,
} from "@fortawesome/pro-light-svg-icons";

import { T, useT } from "@repo/transifex";
import { Button } from "~/components";

import { HelpItem } from "./components/HelpItem";

export const TicketValidationHelpPage = () => {
  const t = useT();
  return (
    <div className="flex min-h-full flex-1 flex-col items-center justify-center bg-white py-10 md:py-0">
      <p className="text-center text-5xl font-bold">
        <T _str="How do I scan my ticket?" />
      </p>
      <div className="my-16 flex h-fit w-full flex-col items-center justify-around gap-y-8 md:flex-row md:items-baseline">
        <HelpItem
          index={1}
          icon={faQrcode}
          title={t("Find your QR code")}
          description={t("It’s either in your app or you have it printed")}
        />
        <HelpItem
          index={2}
          icon={faScannerTouchscreen}
          title={t("Find the scanner")}
          description={t("It’s right next to this display")}
        />
        <HelpItem
          index={3}
          icon={faScanner}
          title={t("Scan the QR code")}
          description={t("Point your ticket to the scanner")}
        />
      </div>
      <Link to="/ticket-validation">
        <Button icon={faArrowLeft} variant="secondary">
          <T _str="Go back" />
        </Button>
      </Link>
    </div>
  );
};
