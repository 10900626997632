import { faBan, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

import { Button, UnattendedLogoutButton } from "~/components";
import { useCodeScanner } from "~/hooks";
import { T, useT } from "@repo/transifex";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { useVerifyTicket } from "~/hooks/queries/tickets";

import { TicketScanSvg } from "./assets/TicketScanSvg";
import { TicketErrorScreen } from "./components/TicketErrorScreen";
import { TicketValidScreen } from "./components/TicketValidScreen";

export const TicketValidationPage = () => {
  const t = useT();

  const {
    data: verificationResponse,
    mutate: verifyTicket,
    status: verificationStatus,
    reset,
  } = useVerifyTicket();

  useCodeScanner({
    onScan: ({ code }) => {
      if (verificationStatus !== "idle") {
        // Stop scanning when a modal is shown, or we are validating a ticket
        return;
      }
      verifyTicket(code);
    },
  });

  // We received Ticket validation response and ticket is valid
  if (verificationStatus === "success" && verificationResponse.isValid) {
    return <TicketValidScreen onClose={reset} />;
  }

  // We received ticket validation response and Ticket is invalid
  if (verificationStatus === "success" && !verificationResponse.isValid) {
    return (
      <TicketErrorScreen
        title={t("Ticket expired")}
        body={t(
          "We couldn’t find your order. Please try again or ask kitchen staff for help"
        )}
        icon={faBan}
        onClose={reset}
      />
    );
  }

  // Ticket validation response failed
  if (verificationStatus === "error") {
    return (
      <TicketErrorScreen
        icon={faBan}
        title={t("Something went wrong")}
        body={t("Please try again or ask kitchen staff for help")}
        onClose={reset}
      />
    );
  }

  return (
    <div className="flex h-full flex-col bg-white p-5">
      <div className="flex w-full flex-1  flex-col items-center justify-center space-y-8">
        {verificationStatus === "pending" ? (
          <LoadingSpinner />
        ) : (
          <>
            <TicketScanSvg />
            <p className="w-8/12 text-center text-5xl font-bold">
              <T _str="Scan your ticket or receipt QR code on the scanner" />
            </p>
            <Link to="help">
              <Button
                size="medium"
                className="bg-slate-50"
                textClassName="text-slate-900"
                icon={faInfoCircle}
              >
                <T _str="How you scan your ticket" />
              </Button>
            </Link>
          </>
        )}
      </div>
      <div className="flex w-full justify-start">
        <UnattendedLogoutButton />
      </div>
    </div>
  );
};
