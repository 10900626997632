import { registerSW } from "virtual:pwa-register";
import * as Sentry from "@sentry/react";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type {
  PersistedClient,
  Persister,
} from "@tanstack/react-query-persist-client";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { del, get, set } from "idb-keyval";
import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { isApiError } from "@repo/system";

import { AppProvider } from "./providers/app";
import { authActions } from "./providers/store/auth";
import { router } from "./router";
import { PWA_ENV } from "./utils/constants";
import "./index.css";
import { logger } from "./services/logger";

Sentry.init({
  dsn: "https://ce59722f92ae462b69fd71101e539389@o327446.ingest.sentry.io/4506717185703936",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/(?:staging\.)?api\.kanpla\.dk\/api$/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.PROD,
  environment: PWA_ENV,
});

// Ensure all pending logs are sent before the app closes
window.addEventListener("beforeunload", () => logger.flush());

const IDB_KEY = "posReactQuery";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Number.POSITIVE_INFINITY,
      staleTime: 1000 * 60, // 1 minute
      refetchOnReconnect: true,
      retry: (_, err) => {
        if (isApiError(err)) {
          // if auth error, don't retry
          if (err.status === 401) {
            return false;
          }

          // if gateway timeout, retry
          if (err.status === 504) {
            return true;
          }
        }

        return false;
      },
    },
  },
  queryCache: new QueryCache({
    onError: (err) => {
      // if the user is not logged in, redirect to the login page
      if (isApiError(err) && err.status === 401) {
        authActions.logout();
      }
    },
  }),
});

registerSW({ immediate: true });

const persister = {
  persistClient: async (client: PersistedClient) => {
    await set(IDB_KEY, client);
  },
  restoreClient: async () => {
    return get<PersistedClient>(IDB_KEY);
  },
  removeClient: async () => {
    await del(IDB_KEY);
  },
} as Persister;

// Handle Vite Load Error (white screen of death)
window.addEventListener("vite:preloadError", () => {
  logger.warn("Vite failed to load dynamic imports - reloading the page");
  window.location.reload();
});

const el = document.getElementById("root");
if (el) {
  const root = createRoot(el);
  root.render(
    <React.StrictMode>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <AppProvider>
          <RouterProvider router={router} />
        </AppProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
    </React.StrictMode>
  );
} else {
  throw new Error("Could not find root element");
}
