import { T, t } from "@repo/transifex";
import {
  Button,
  Dialog,
  DialogError,
  type DialogProps,
  DialogSuccess,
} from "~/components";
import { usePrintOrderReceipt } from "~/hooks/queries";
import { usePrinterError, usePrinterStatus } from "~/providers/app";
import { isApiError } from "@repo/system";

type DialogPrintReceiptProps = Partial<DialogProps> & {
  orderId: string;
};

export const DialogPrintReceipt = ({
  orderId,
  ...dialogProps
}: DialogPrintReceiptProps) => {
  const printerStatus = usePrinterStatus();
  const printerError = usePrinterError();

  const {
    mutate: createReceipt,
    error,
    status,
    reset,
  } = usePrintOrderReceipt();

  if (printerStatus === "error" || status === "error")
    return (
      <DialogError
        error={
          status === "error" && isApiError(error)
            ? error.body.message
            : printerError
        }
        onOutsideClick={() => {
          dialogProps.onOutsideClick?.();
          reset();
        }}
      />
    );

  if (printerStatus === "success")
    return (
      <DialogSuccess
        onOutsideClick={() => dialogProps.onOutsideClick?.()}
        title={t("Receipt has been printed")}
      />
    );

  return (
    <Dialog size="md" {...dialogProps}>
      <div className="flex flex-col items-center justify-center px-8 py-5 text-center">
        <div className="mb-8 flex flex-col gap-3 text-text-secondary">
          <h2 className="text-xl">
            <T _str="Do you want to print this receipt?" />
          </h2>
        </div>

        <div className="flex gap-3">
          <Button
            onClick={() => dialogProps.onOutsideClick?.()}
            variant="danger-light"
          >
            <T _str="Cancel" />
          </Button>

          <Button
            loading={status === "pending"}
            onClick={() => {
              createReceipt({ orderId });
            }}
          >
            <T _str="Confirm" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
