import { v4 as uuidv4 } from "uuid";

import type { Product, StepVariant, StepVariantWeight } from "@repo/types";

import { basketStore } from "./store";
import type {
  DiningOption,
  DiscountAmountType,
  ProductCustom,
  ProductVariant,
} from "./store.types";

function addRegular(productId: Product["id"]) {
  basketStore.setState((state) => {
    const index = state.products.findIndex(
      (prod) =>
        prod.type === "regular" &&
        prod.productId === productId &&
        typeof prod.discountUnitPrice === "undefined" &&
        typeof prod.stampCardRuleId === "undefined"
    );

    if (index !== -1) {
      state.products[index].count += 1;
    } else {
      state.products.push({
        type: "regular",
        productId,
        id: uuidv4(),
        count: 1,
      });
    }
  });
}

function addCustomProducts(
  customProducts: Omit<ProductCustom, "type" | "id">[]
) {
  basketStore.setState((state) => {
    for (const product of customProducts) {
      state.products.push({
        type: "custom",
        ...product,
        id: uuidv4(),
      });
    }
  });
}

function editVariants(
  basketId: string,
  variants: StepVariant[],
  amount: number
) {
  basketStore.setState((state) => {
    const index = state.products.findIndex(
      (prod) => prod.id === basketId && prod.type === "variant"
    );

    if (index !== -1) {
      (state.products[index] as ProductVariant).options = variants;
      state.products[index].count = amount;
    }
  });
}

function addVariants(
  productId: Product["id"],
  variants: StepVariant[],
  amount: number
) {
  basketStore.setState((state) => {
    state.products.push({
      type: "variant",
      productId,
      id: uuidv4(),
      options: variants,
      count: amount,
    });
  });
}

function setDiscount(productBasketId: string, discountUnitPrice: number) {
  basketStore.setState((state) => {
    if (discountUnitPrice === 0) {
      removeDiscount(productBasketId);
      return;
    }

    const index = state.products.findIndex(
      (prod) => prod.id === productBasketId
    );

    if (index !== -1) {
      state.products[index].discountUnitPrice = discountUnitPrice;
    }
  });
}

function removeDiscount(productBasketId: string) {
  basketStore.setState((state) => {
    const index = state.products.findIndex(
      (prod) => prod.id === productBasketId
    );

    if (index !== -1) {
      delete state.products[index].discountUnitPrice;
    }
  });
}

function increment(id: string) {
  basketStore.setState((state) => {
    const index = state.products.findIndex((prod) => prod.id === id);

    if (index !== -1) {
      state.products[index].count += 1;
    }
  });
}

function updateAmount(id: string, amount: number) {
  basketStore.setState((state) => {
    const index = state.products.findIndex((prod) => prod.id === id);

    if (index !== -1) {
      state.products[index].count = amount;
    }
  });
}

function remove(id: string) {
  basketStore.setState((state) => {
    const index = state.products.findIndex((prod) => prod.id === id);

    if (index !== -1) {
      state.products.splice(index, 1);
    }
  });
}

function reset() {
  basketStore.setState({
    products: [],
    orderDiscount: { amount: 0, type: "currency" },
    shopperName: "",
  });
}

function setOrderDiscount(orderDiscount: {
  amount: number;
  type: DiscountAmountType;
}) {
  basketStore.setState({ orderDiscount });
}

function addVariantWeight(
  productId: Product["id"],
  variants: StepVariantWeight[],
  unitSystem: Product["unitSystem"]
) {
  basketStore.setState((state) => {
    state.products.push({
      type: "variant-weight",
      productId,
      id: uuidv4(),
      options: variants,
      count: 1,
      unitSystem: unitSystem as Exclude<Product["unitSystem"], "piece">,
    });
  });
}

function addRedeemed(
  productId: Product["id"],
  discountUnitPrice: number,
  stampCardRuleId: string
) {
  basketStore.setState((state) => {
    state.products = state.products.filter(
      (product) => product.stampCardRuleId !== stampCardRuleId
    );

    state.products.push({
      type: "regular",
      productId,
      id: uuidv4(),
      count: 1,
      discountUnitPrice,
      stampCardRuleId,
    });
  });
}

function removeRedeemed() {
  basketStore.setState((state) => {
    state.products = state.products.filter(
      (prod) => typeof prod.stampCardRuleId === "undefined"
    );
  });
}

/**
 * Update selected dining option
 * @param {string} option a text input value defined via the admin text input plugin
 */
function setDiningOption(diningOption: DiningOption) {
  basketStore.setState(() => ({ diningOption }));
}

/**
 * Reset selected dining option
 */
function resetDiningOption() {
  basketStore.setState(() => ({ diningOption: null }));
}

function setCardPaymentInProgress(newState: boolean) {
  basketStore.setState((state) => {
    state.isCardPaymentInProgress = newState;
  });
}

function setShopperName(shopperName: string) {
  basketStore.setState(() => ({ shopperName }));
}

const basketActions = {
  addCustomProducts,
  addRegular,
  addVariants,
  updateAmount,
  editVariants,
  increment,
  remove,
  reset,
  setDiscount,
  removeDiscount,
  addVariantWeight,
  setOrderDiscount,
  setDiningOption,
  resetDiningOption,
  setCardPaymentInProgress,
  setShopperName,
  addRedeemed,
  removeRedeemed,
};

export { basketActions };
