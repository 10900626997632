import { faQrcode } from "@fortawesome/pro-light-svg-icons";
import { faGear } from "@fortawesome/pro-solid-svg-icons";

import { useT } from "@repo/transifex";

import {
  Menu,
  MenuHeader,
  MenuContent,
  MenuColumn,
  MenuFooter,
  MenuLink,
} from "./components";

export const TicketValidationMenu = () => {
  const t = useT();
  return (
    <Menu>
      <MenuHeader />
      <MenuContent>
        <MenuColumn>
          <MenuLink
            icon={faQrcode}
            isBig
            title={t("Scan")}
            to="/ticket-validation"
          />
        </MenuColumn>
        <MenuColumn>
          <MenuLink
            icon={faGear}
            isBig
            title={t("Settings")}
            to="/settings/devices"
          />
        </MenuColumn>
      </MenuContent>
      <MenuFooter />
    </Menu>
  );
};
